<template>
  <div>
    <v-app-bar flat color="white">
      <content-dialog-component
        ref="refContentDialogComponent"
      ></content-dialog-component>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import contentDialogComponent from "./component/contentDialogComponent.vue";

export default {
  components: {
    contentDialogComponent,
  },
  setup(props, ctx) {
    const refContentDialogComponent = ref(null);

    // app-barにあるコンポーネント呼び出し
    const action = (params) => {
      // switch (params.action) {
      //   case "edit":
      //     refContentDialogComponent.value.editDialog(params.value);
      //     break;
      // }
    };

    return {
      refContentDialogComponent,
      action,
    };
  },
};
</script>
