<template>
  <div>
    <v-btn class="info font-weight-bold" @click="onClick"
      ><v-icon left>mdi-plus-box</v-icon
      ><span class="d-none d-sm-flex">新規登録</span
      ><span class="d-sm-none">新規</span></v-btn
    >
    <select-component
      ref="refSelectComponent"
      @selected="selected"
    ></select-component>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import selectComponent from "./selectComponent.vue";

export default defineComponent({
  components: {
    selectComponent,
  },
  setup(props, ctx) {
    const { router } = useRouter();
    const refSelectComponent = ref(null);

    const onClick = async () => {
      await refSelectComponent.value.showDialog();
    };

    const selected = async (param) => {
      if (param.update_content_id != null) {
        await router.push({
          name: "synchro-contents-edit",
          params: {
            update_content_id: param.update_content_id,
          },
        });
      } else {
        await router.push({
          name: "synchro-contents-add",
          params: {
            shop_site_id: param.shop_site_id,
            site_content_id: param.site_content_id,
          },
        });
      }
    };

    return {
      setting,
      refSelectComponent,
      onClick,
      selected,
    };
  },
});
</script>
