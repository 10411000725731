var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"100%","max-width":"1200px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("コンテンツ選択")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"キーワード検索","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"scrollable-content"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.siteContents,"search":_vm.search,"items-per-page":50,"mobile-breakpoint":0,"footer-props":{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        },"no-data-text":"対応コンテンツがありません"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"body",fn:function(ref){
        var siteContents = ref.items;
return [_c('tbody',_vm._l((siteContents),function(siteContent){return _c('tr',{key:siteContent.index,class:"content_id_" +
                siteContent.content_id +
                ' ' +
                siteContent.tr_class,on:{"click":function($event){return _vm.onClickList(siteContent)}}},[_c('td',{staticClass:"text-start"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(siteContent.content_icon)+" ")]),_c('span',{staticClass:"text-align-middle mini"},[_vm._v(" "+_vm._s(siteContent.content_name))])],1),_c('td',{staticClass:"text-start"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(siteContent.site_content_name))])]),_c('td',{staticClass:"text-start"},[_c('span',{staticClass:"shop_site_span",style:(siteContent.color.length != 0
                      ? 'border:dashed 1px ' +
                        siteContent.color +
                        '; color:' +
                        siteContent.color
                      : '')},[_vm._v(_vm._s(siteContent.site_name))])]),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(siteContent.setting)+" ")]),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(siteContent.min_interval == 0 ? "-" : siteContent.min_interval + "分")+" ")])])}),0)]}}])})],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":"キャストとスケジュールを表示する","hide-details":"false"},on:{"change":_vm.onChange},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":""},on:{"click-event":_vm.cancelDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("キャンセル")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }